.page-link{
  cursor: pointer;
}
.select-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh
}
.select-container .site{
  position: relative;
  flex: 1;
  width: 100%;
  overflow: hidden;
}
.select-container .site a{
  text-decoration: none;
  cursor: pointer;
}
.film{
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.6);
  height: 100%;
  width: 100%;
  z-index: 2;
}
.select-container .site .site-content{
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 100%;
}
.select-container .site img{
  width: 100vw;
  height: 100%;
  position: absolute;
  left: 0;
  transition: transform .2s; /* Animation */
}
.select-container .site .site-content h1{
  color: #fff;
  font-size: 4em;
  opacity: .5;
  z-index: 1;
  text-decoration: none;
}
​
.select-container .site:hover img{
  transform: scale(1.1); 
}
.select-container .site:hover a img{
  transform: scale(1.1); 
}
​
.select-container .site:hover .film{				
  background: rgba(0,0,0,.3);
}
.select-container .site:hover div.film{				
  background: rgba(0,0,0,.3);
}

.select-container .site:hover .site-content h1{
  color: #fff;
  opacity: 1;
}
